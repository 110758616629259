.hero-container {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2.5em;
}

.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: saturate(40%);
  background-size: cover;
}

.colored-filter {
  opacity: 80%;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
}

header {
  height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 101;

  .header-details {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8em;

    .reading-time {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      filter: opacity(70%);

      .clock-icon {
        mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        mask-position: center;
        height: 1.1em;
        width: 1.1em;
      }
    }
  }

  .down-arrow-icon {
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    height: 1.2em;
    width: 1.2em;
    margin-top: 2.5em;
    margin-bottom: 2em;
  }

}