.section-normal {
    padding: 0 8%;

    p {
        font-size: 1.1em;
        padding-top: 0.5em;
    }
}

@media (min-width: 600px) {
    .section-normal {
        padding: 0 15%;
    }
}