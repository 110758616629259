.related {
    padding: 4em 8%;
    background: linear-gradient(180deg, #EFF1F1 1.49%, #F0F0F0 23.37%);

    .related-title {
        display: flex;
        justify-content: center;
        gap: 2em;
        align-items: center;
        margin: 2.5em 0;
        color: #333333;

        .line {
            width: 20%;
            border-top: 0.04em solid #333333;
        }
    }

    .related-container {
        display: flex;
        justify-content: baseline;
        flex-wrap: wrap;
        gap: 1em
    }
}

@media (min-width: 800px) {
    .related {
        padding: 4em 5%;
    }
}

@media (min-width: 1000px) {
    .related {
        padding: 4em 12%;
    }
}