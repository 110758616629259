.comparison-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.post-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.post-left {
  z-index: 1;
}

.post-right {
  z-index: 2;
  pointer-events: auto;
  /* Make sure the right post is always interactable */
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  /* Transparent mask */
  z-index: 3;
  pointer-events: none;
  /* Ensure mask does not block interaction */
}

.slider {
  position: absolute;
  top: 0;
  height: 100%;
  width: 8px;
  /* Slightly wider slider for better visibility */
  background-color: #4CAF50;
  cursor: ew-resize;
  z-index: 4;
  border-radius: 4px;
  /* Rounded edges */
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  /* Add shadow effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transitions */
}

/* Hover and active effects for slider */
.slider:hover {
  background-color: #F1F0EF;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.3);
}

.slider:active {
  background-color: #388e3c;
  box-shadow: 2px 0 18px rgba(0, 0, 0, 0.4);
}