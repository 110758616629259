.framed-area {
    padding: 0 14%;

    .framed-title {
        margin-bottom: 0.6em;
        font-weight: bold;
    }

    p {
        font-size: 1.1em;
        white-space: pre-line;
        opacity: 1;
    }

}

@media (min-width: 600px) {
    .framed-area {
        padding: 0 24%
    }
}

@media (min-width: 1000px) {
    .margin {
        padding: 2em;
    }
}