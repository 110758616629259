@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=Shadows+Into+Light+Two&display=swap');

.numbered-section-container {
    margin: 1.5em 0;
    padding: 0 10%;

    .numbered-section {
        position: relative;
        padding: 1em;

        .numbered-watermark {
            font-weight: bold;
            filter: opacity(0.08);
            position: absolute;
            top: -2.5em;
            left: -0.5em;

            p {
                font-size: 9em;
            }
        }

        .numbered-text {
            font-size: 1.1em;
        }
    }
}

@media (min-width: 600px) {
    .numbered-section-container {
        padding: 0 16%;
    }
}