.gallery-container {
    height: 95vh;
    overflow-x: auto;
    display: flex;

    .gallery-image {
        margin-right: .3em;
        height: 100%;
        flex-basis: 22em;
        flex-shrink: 0;
        background-size: cover;
        background-position: center bottom;
        position: relative;
        overflow: hidden;
        filter: saturate(95%);
    }

    .gallery-image:last-of-type {
        margin-right: 0;
    }
}

@media (min-width: 800px) {

    .gallery-container {
        height: 90vh;

        .gallery-image {
            flex-basis: 36em;
        }
    }
}