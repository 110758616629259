.card-container {
    height: 240px;
    width: 350px;
    display: flex;
    flex-direction: column;

    >a {
        text-decoration: inherit;
        color: inherit;
        height: 100%;
        width: 100%;
    }

    .card-img-container {
        position: relative;
        height: 50%;
    }

    .card-img {
        height: 100%;
        width: 100%;
        background-size: cover;
        filter: saturate(0%);
        border-radius: .4em .4em 0 0;
        position: absolute;
    }

    .card-img-grey-filter {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: .4em .4em 0 0;
        opacity: 80%;
        background-color: #AEAEAE;
        display: block;
    }

    .card-img-color-filter {
        display: none;
    }

    .card-txt {
        padding: 1em;
        background-color: #FAFAFA;
        border-radius: 0 0 .4em .4em;
        color: #333333;
    }

    .card-details {
        display: flex;
        justify-content: space-between;
        margin-top: 1em;

        .reading-time {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.3em;
            filter: opacity(70%);

            .clock-img {
                height: 17px;
                width: 17px;
            }
        }
    }
}

.card-container:hover {
    .card-img {
        filter: saturate(50%);
    }

    .card-img-color-filter {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: .4em .4em 0 0;
        opacity: 80%;
        display: block;
    }

    .card-img-grey-filter {
        display: none;
    }
}