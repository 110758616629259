@import '../styles/colorDefinitions.scss';

* {
  margin: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');

body {
  font-family: "PT Sans Narrow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.4em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.3em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.8em;
}

p {
  font-size: 1em;

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.01em;
    text-decoration: underline;
  }
}

.container {
  margin: 3em 0;
}