.footer {
    background-color: #F0F0F0;
    color: #000000;
    padding: 3em 8%;

    p {
        font-size: 0.7em;
    }
}

@media (min-width: 600px) {
    .footer {
        padding: 3em 15%;
    }
}